import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { graphql } from 'gatsby';
import Head from 'components/head';
import SectionMain from 'components/section-main';
import Title from 'components/title';
import CtaButton from 'components/ctabutton';
import ProfileImage from 'components/profile-image';
import { Aside } from 'components/section-main/aside.css';

const TiliaRuggeri = ({ data }) => {
  const PAGE = data.tiliaJson;
  const TILIA = data.tiliaJson.sections[0];

  return (
    <>
      <Head
        pageTitle={PAGE.title}
        imageUrl={PAGE.socialImage.childImageSharp.fixed.src}
        siteDescription={PAGE.metaDescription}
      />

      <SectionMain>
        <ProfileImage color="green">
          <Img fluid={TILIA.image.childImageSharp.fluid} />
        </ProfileImage>

        <Title as="h1" color="blue">
          {TILIA.title}
        </Title>

        <Title as="h3" color="pink" size="extrasmall">
          {TILIA.subTitle}
        </Title>

        <div
          className="body"
          dangerouslySetInnerHTML={{
            __html: TILIA.content.childMarkdownRemark.html,
          }}
        />

        <CtaButton linkTo="/" fontWeight="bold" style="rounded">
          {TILIA.cta}
        </CtaButton>

        <Aside />
      </SectionMain>
    </>
  );
};

TiliaRuggeri.propTypes = {
  data: PropTypes.object.isRequired,
};

export default TiliaRuggeri;

export const query = graphql`
  query {
    tiliaJson {
      title
      metaDescription
      socialImage {
        childImageSharp {
          fixed(width: 1025, height: 512, cropFocus: NORTH) {
            src
          }
        }
      }
      sections {
        title
        subTitle
        content {
          childMarkdownRemark {
            html
            rawMarkdownBody
          }
        }
        image {
          childImageSharp {
            fluid(maxWidth: 640) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;
